.FAQ {
    flex: 0 1 auto;
    margin-left: 22%;  
    margin-right: 50px;
    margin-top: 20px;
    margin-bottom: 40px;
    width: 74.5%;
    height: fit-content !important;
    min-height:50vh;
    background-color: #22222A;
    border-radius: 10px;
    padding-left: 50px;
    padding-top: 15px;
    padding-right: 60px !important;
    color: white;
}

.FAQ_title_div {
    margin-bottom: 30px;
}

.FAQ_title {
    font-size: 2.6vw;
    margin-bottom: 10px;
    border-bottom: 3px solid rgba(175, 175, 175, 0.425); 
    padding-bottom: 5px;
    color: white;
    font-family:Outfit; 
    font-weight: 600;
}

.FAQ_content {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    flex-wrap: wrap; 
}

.FAQ_points {
    flex: 1; 
    margin-right: 20px; 
    font-family:Outfit; 
    font-weight: 500;
}

.FAQ_points div {
    font-size: 1.2em;
    margin-top: 20px; 
}

.FAQ_points ul {
    list-style-type: disc; 
    padding-left: 20px;
    font-family:Outfit; 
    font-weight: 400;
    margin-left: 15px;
    line-height: 1.5; 
    font-size: 1em; 
}

.FAQ_image {
    max-width: 40%; 
    height: auto;
    
}

@media (max-width: 600px){
   .FAQ{
    margin: 15px !important;
    margin-bottom: 20px;
    padding-top: 20px !important;
    padding-left: 25px !important;
    padding-right: 25px !important;
    padding-bottom: 40px;
    width: 92% !important;
   }
    .FAQ_title {
        font-size: 1.5em; 
        line-height: 50px;
    }

    .FAQ_content {
        flex-direction: column-reverse;
        align-items: flex-start;
    }

    .FAQ_image {
        max-width: 80%; 
        margin-bottom: 20px; 
        align-self: center;
    }

    .FAQ_points {
        margin-right: 0;
        text-align: left; 
        width: 100%;
        font-size: 1.1;
    }

    .FAQ_points div {
        font-size: 1.1em; 
        margin-top: 15px; 
    }

    .FAQ_points ul {
        font-size: 1.1em;
    }
}
