.landing-page {
  font-family: Outfit;
    color: #333;
    background-color: black !important;
    height: 100%;
    width: 100%;
  }
  
  
  /* General header styles */
.header-landing-page {
  display: flex;
  justify-content: space-between;
  height: 80px;
  padding: 20px 80px;
  background: linear-gradient(187.79deg, rgba(100, 35, 247, 0.4) 6%, rgba(0, 0, 0, 0.296) 111.24%);
  position: fixed; /* Fixes the header at the top */
  top: 0; /* Aligns it at the top of the page */
  left: 0; /* Aligns it to the left */
  width: 100%; /* Ensures the header stretches across the entire width */
  z-index: 1000; 
  background-color: black;


}

.header-left-landing-page {
  display: flex;
  gap: 10px;
}

.logo-landing-page {
  width: 40px; /* Adjust the size of the logo */
  height: 40px;
}

.title-landing-page {
  font-size: 20px;
  color: white;
  margin-top: 3%;
  
}

.header-middle-landing-page {
  display: flex;
  justify-content: space-between;
  width: 40%;
  gap: 10px;
}

.header-btn-landing-page {  
  background-color: transparent;
  color: white;
  border: none;
  padding: 10px 20px;
  cursor: pointer;
  font-size: 16px;
  border-radius: 5px;
}

.header-btn-landing-page:hover {
  background-color: #3700b3; 
}

.header-right-landing-page #login-btn-landing-page {
  background-color: transparent; 
  color: white;
  border: 1px solid white;
  padding: 10px 20px;
  cursor: pointer;
  font-size: 16px;
  border-radius: 25px;
}





#hamburger-menu_hero._hero {
  display: none;
   
}

.Hero_landing-page{
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  gap: 20px;
  padding: 100px;
 margin-top: 80px;
  background: radial-gradient(circle at 25% 20%, #762bdeaf, transparent 20%), 
              radial-gradient(circle at 70% 30%, #36a0c793, transparent 30%);

}

.Hero_title{
  font-size: 5em;
  background: linear-gradient(180deg, #762ADF 0%, #32A9C6 100%);
  -webkit-background-clip: text; 
  background-clip: text;
  color: transparent; 
  font-family: Outfit;
}
.Hero-subtitle{
  color: white;
  font-size: 2em;
  
  font-weight: 300;
}


.view-dashboard{
  margin: 50px;
  padding: 12px 40px;
  background: linear-gradient(180deg, #762ADF 0%, #32A9C6 100%);
  color: white;
  font-size: 1.3em;
  border: none;
  border-radius: 20px;
}

.heroimage {
  height: auto;
  max-width: 1200px;
  width: 80%;  
  opacity: 0; /* Initially invisible */
  transform: translateY(50px); /* Start slightly below */
  transition: opacity 1s ease, transform 1s ease; 
}
.heroropeimage{
  width: 5%;
  height: auto;
  aspect-ratio: 16/9;
}

/* Class that will be added once the image is in the viewport */
.heroimage.visible {
  opacity: 1;
  transform: translateY(0); /* Slide to its natural position */
}


.video_showcase{
background: linear-gradient(180deg, #762ADF 0%, #32A9C6 100%);
display: flex;
flex-direction: row;
gap: 10px;
padding: 40px 40px;
justify-content: center;
width: 100%;
height: fit-content;
}
.Video_showcase_text_box{
  width: 45%;
text-align: left;
display: flex;
flex-direction: column;
justify-content: center;
}
.video_showcase_text_heading{
  color: white;
  font-size: 3em;
}
.video_showcase_text_subheading{
  color: rgba(255, 255, 255, 0.5);
}
.video_showcase_video{
  background-color: rgb(24, 21, 21);
  border: none;
  border-radius: 20px;
  overflow: hidden;
  aspect-ratio: 9/16;
}
.Video_showcase_mobile{
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%; /* Full width of the container */
  height: fit-content;
  
  position: relative;
  gap: 20px;
}
.steps_box{
  padding: 40px;
  text-align: center;
  align-items: center;
  background-color: black;
  background: radial-gradient(circle at 45% 20%, #762bdeaf, transparent 40%), 
              radial-gradient(circle at 70% 90%, #36a0c793, transparent 40%);
display: flex;
flex-direction: column;
gap: 40px;
}

.steps_title{
  font-size: 3em;
  background: linear-gradient(180deg, #762ADF 0%, #32A9C6 100%);
  -webkit-background-clip: text; 
  background-clip: text;
  color: transparent; 
  font-family: Outfit;
}
.steps_subtitle{
  color: white;
  font-size: 2em;
  
  font-weight: 300;
}

.step_number{
background: linear-gradient(180deg, #762ADF 0%, #32A9C6 100%);
color: white;
border: none;
border-radius: 60%;
font-size: 2em;
height: fit-content;
padding: 20px;
width: fit-content;
}
.steps_text_title{
  color: white;
  font-size: 3em;
  width: fit-content;
}
.steps_text_detail{
  font-size: 1em;
  color:  #FFFFFF80;
  width: 50%;
  text-align: left;
 
}
 .steps_each_box{
  display: flex;
  flex-direction: row;
  gap: 20px;
  align-items: center;

} 

.all_steps{
  background-image: url(../../assets/rope.svg) ;
  background-repeat: no-repeat;
 
  background-position:center;
}


.step3,.step1 {
  display: flex;
  flex-direction: row;
  gap: 40px;
  margin-bottom: 10vh;
}
.step2,.step4{
  display: flex;
  flex-direction: row-reverse;
  gap: 40px;
  margin-bottom: 10vh;
}

.subscription{
  background-color: #6423F7;
  align-items: center;
  text-align: center;
  padding: 40px;
  display: flex;
  flex-direction: column;
  gap: 40px;
}
.subscription_title{
  color: white;
  width: 50%;
  font-size: 1.5em;
}
.subscription_subtitle{
  color: white;
  width: 50%;
  font-size: 2em;
}
.plans_wrapper{
  background-color: white;
  border: none;
  border-radius: 20px;
  width: 80%;
  display: flex;
  flex-direction: row;
  padding: 10px;
  justify-content: center;
}

.plan-1,.plan-2,.plan-3{
margin: 20px;
display: flex;
flex-direction: column;
justify-content: space-between;
gap: 20px;
text-align: left;
border-radius: 10px;
padding: 20px;
max-width: 350px;
}
.plan-1:hover,.plan-2:hover,.plan-3:hover{
 
  margin: 20px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 20px;
  text-align: left;
  border-radius: 10px;
  padding: 20px;
  max-width: 350px;
  background: linear-gradient(180deg, #762ADF 0%, #32A9C6 100%);
   
}

.plan-1:hover,.plan-2:hover,.plan-3:hover .discription{
  color: white;
}

/* .plan-3{
  margin: 20px;
display: flex;
flex-direction: column;
gap: 20px;
text-align: left;
background: linear-gradient(180deg, #762ADF 0%, #32A9C6 100%);
border-radius: 20px;
padding: 20px;
max-width: 25%;
}*/

.plan-1:hover .price1, .plan-2:hover .price2, .plan-3:hover .price3{
  
  background: white;
  -webkit-background-clip: text; 
  background-clip: text;
  color: transparent; 
  font-family: Outfit;
  font-weight: bolder;
} 
.plan-1:hover .ponits_list1,.plan-2:hover .ponits_list2,.plan-3:hover .ponits_list3{
  color: white;
}

.plan-1:hover .discription_subtitle1, .plan-2:hover .discription_subtitle2, .plan-3:hover .discription_subtitle3{
color: white;
}


.price1,.price2,.price3{
  font-size: 2em;
  background: linear-gradient(180deg, #762ADF 0%, #32A9C6 100%);
  -webkit-background-clip: text; 
  background-clip: text;
  color: transparent; 
  font-family: Outfit;
  font-weight: bolder;
}
.white_check1,.white_check2,.white_check3{
  display: none !important;
}
.price_details{
  display: flex;
  flex-direction: row;
  
  align-items: center;  
}
.price_interval,.price_interva2,.price_interva3{
  color:#848199 ;
}

.plan-1:hover .price_interval,.plan-2:hover .price_interval2,.plan-3:hover .price_interval3{

color: white;
}

.plan-1:hover .colored_check1 {
  display: none;
}

.plan-1:hover .white_check1 {
  display: block !important;
}

.plan-2:hover .colored_check2 {
  display: none ;
}

.plan-2:hover .white_check2 {
  display: block !important;
}

.plan-3:hover .colored_check3 {
  display: none;
}

.plan-3:hover .white_check3 {
  display: block !important;
}


.discription1,.discription2,.discription3{
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin-bottom: 10px;
}

.discription_subtitle{
  color: #848199;
}


.ponits_list1,.ponits_list2,.ponits_list3{
  color: #3b3b3d;
  display: flex;
  flex-direction: row;
  gap: 10px;
  
}

.points_list_full{
  display: flex;
  flex-direction: column;
  gap: 12px;
}
.plan-1:hover .choose_plan1,.plan-2:hover .choose_plan2,.plan-3:hover .choose_plan3{
  background-color: white;
  color: black;
}
.choose_plan1,.choose_plan2,.choose_plan3{
 background-color: #e7e1e1;
 font-size: 1.2em;
 color: #313131;
 border: none;
 border-radius: 20px; 
 padding: 5px;
 width: 200px;
 align-self:center;
  
}
.MOSTPOPULAR{
 background-color: white;
 color: linear-gradient(180deg, #762ADF 0%, #32A9C6 100%);
  border: none;
   border-radius: 20px;
   text-align: right;
   width: fit-content;
   padding: 8px;
  font-family: Outfit;
  font-weight: 800;
  justify-self: end; 
}

.faq-container_hero {
  background-color: black;
  width: 100%;
  padding: 5%;
  color: white;

  display: flex;
  flex-direction: column;
  gap: 20px;
  justify-content: center; 
  align-items: center;     
  
}

.faq-title_hero {
  text-align: center;
  margin-bottom: 30px;
  font-size: 2rem;
  font-weight: bold;
  text-transform: uppercase;
  font-family: Outfit;
}


.faq-items_hero {
  display: flex;
  flex-direction: column;
  gap: 20px;
  width: 80%;
  
}


.faq-item_hero {
  background-color: #FFFFFF0F; 
  padding: 10px 30px; 
  border-radius: 8px;
  cursor: pointer;
  transition: all 0.3s ease-in-out;
}


.faq-item_hero:hover {
  background-color: rgba(255, 255, 255, 0.3); 
}


.faq-question_hero {
  display: flex;
  gap: 40px;
  align-items: center;
  font-size: 1.2rem;
  font-weight: bold;
  cursor: pointer;
  transition: transform 0.3s ease-in-out;
}

.plus-icon_hero {
  font-size: 1.5rem;
  margin-right: 10px;
  transition: transform 0.3s ease-in-out;
}


.faq-question_hero.open .plus-icon_hero {
  transform: rotate(45deg); 
}


.faq-answer_hero {
  margin-top: 20px;
  padding-top: 10px;
  font-size: 1em;
  color:white; 
  
  border-top: 1px solid rgba(255, 255, 255, 0.2);
  max-height: 0; 
  opacity: 0; 
  overflow: hidden; 
  transition: max-height 0.3s ease-in-out, opacity 0.3s ease-in-out;
}


.faq-item_hero.active .faq-answer_hero {
  max-height: 1000px; 
  opacity: 1;
}


@media (max-width: 1124px) {
 
  .faq-item_hero {
    background-color: #FFFFFF0F; 
    padding: 10px 10px; 
    border-radius: 8px;
    cursor: pointer;
    transition: all 0.3s ease-in-out;
  }
  .faq-container_hero {
  
    padding: 20px 0px 10px 0px;
  }
  .faq-title_hero {
    font-size: 1.5rem; 
  }

  .faq-question_hero {
    font-size: 1rem;
  }

  .faq-answer_hero {
    font-size: 0.9rem; 
  }
}



.footer_terms{
  display: flex;
  flex-direction: row;
  justify-content:space-around;
  font-size: 1em;
  color: white;
  font-family: Outfit;
  border-top:1px solid rgba(255, 255, 255, 0.24) ;
}


.footer_top{
  display: flex;
  flex-direction: row;
  gap: 30px;
  justify-content: center;
  justify-self: center;
  width: 70%;
  padding-bottom: 10px;
}

.form_footer{
  width: 20%;
  border-right:1px solid rgba(255, 255, 255, 0.295) ;
  display: flex;
  flex-direction: column;
  gap: 10px;
}
.product_footer{
  border-right:1px solid rgba(255, 255, 255, 0.295) ;
  padding-right: 20px;
  display: flex;
  flex-direction: column;
  gap: 10px;
}
.contact_footer{
  
  padding-right: 20px;
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.footer_headings{
  color: white;
  font-family: Outfit;
  font-size: 2em;
  font-weight: 600;
}
.footer_subheadings{
  color: white;
  font-size: 1.2em;
  cursor: pointer;
  margin-bottom: 5px;
}



@media (max-width: 1124px) {

  #login_dropdown_hero{
    background-color: white;
    border: none;
    font-size: 1em;
    color: Black;
    
  }

  .header-landing-page {
    display: flex;
    justify-content: space-between; 
    padding: 20px 20px;
    align-items: center;
    background: radial-gradient(circle at 25% 20%, #762bdeaf, transparent 80%), 
    radial-gradient(circle at 70% 30%, #36a0c793, transparent 80%);
    background-color:black;
    
  }
  
  .header-left-landing-page {
    display: flex;
    margin: 0 auto;
    gap: 10px;
    align-items: center;
    justify-content:center;
    flex-grow: 1; /* Align the items to the right */
  }
  
  .logo-landing-page {
    width: 40px; /* Adjust the size of the logo */
    height: 40px;
  }
  
  .title-landing-page {
    font-size: 20px;
    color: white;
    
    
  }
  
  .header-middle-landing-page {
    display: none;
    justify-content: space-between;
    width: 40%;
    gap: 10px;
  }
  
  .header-btn-landing-page {  
    background-color: transparent;
    color: white;
    border: none;
    padding: 10px 20px;
    cursor: pointer;
    font-size: 16px;
    border-radius: 5px;
  }
  .hamburger-menu-container {
    display: flex;
    justify-content: flex-end;  /* Align hamburger to the right */
    align-items: center;  /* Center hamburger icon vertically */
  }
  .header-btn-landing-page:hover {
    background-color: #3700b3; 
  }
  
  .header-right-landing-page #login-btn-landing-page {
    display: none;
    background-color: transparent; 
    color: white;
    border: 1px solid white;
    padding: 10px 20px;
    cursor: pointer;
    font-size: 12px;
    border-radius: 25px;
  }
  
 
  /* Basic styling for the hamburger menu container */
#hamburger-menu_hero._hero {
  display: block;
  margin-left: auto; 
}

/* Hamburger icon styling */
.hamburger-icon_hero {
  font-size: 30px;
  cursor: pointer;  
  color: white;  
  border-radius: 5px;
}

/* Dropdown content (hidden by default) */
.dropdown-content_hero {
  position: absolute;
  right: 0; /* Align it to the right of the hamburger icon */
  top: 55px; /* Position it just below the hamburger icon */
  background-color:white;
  border-radius: 5px;

  padding: 10px 0;
  width: 200px; /* Adjust the width of the dropdown */
}

/* Dropdown options styling */
.dropdown-content_hero a {
  color: black;
  text-decoration: none;
  padding: 8px 20px;
  display: block;
}


  
  
  
  .Hero_landing-page{
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    gap: 20px;
    padding: 0px;
  
    background: radial-gradient(circle at 25% 20%, #762bdeaf, transparent 20%), 
                radial-gradient(circle at 70% 30%, #36a0c793, transparent 30%);
  
  }
  
  .Hero_title{
    font-size: 2em;
    background: linear-gradient(180deg, #762ADF 0%, #32A9C6 100%);
    -webkit-background-clip: text; 
    background-clip: text;
    color: transparent; 
    font-family: Outfit;
  }
  .Hero-subtitle{
    color: white;
    font-size: 1em;
    
    font-weight: 300;
  }
  
  
  .view-dashboard{
    margin: 50px;
    padding: 12px 20px;
    background: linear-gradient(180deg, #762ADF 0%, #32A9C6 100%);
    color: white;
    font-size: 1em;
    border: none;
    border-radius: 10px;
  }
  
  .heroropeimage{
   width:10% ;
   height: auto;
  aspect-ratio: 16/9; 
  }
  
  .video_showcase{
  background: linear-gradient(180deg, #762ADF 0%, #32A9C6 100%);
  display: flex;
  flex-direction: column;
  gap: 10px;
  padding: 40px 40px;
  justify-content: center;
  overflow: hidden;
  width: 100%;
  height: fit-content;
  }
  .Video_showcase_text_box{
    width: 100%;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  }
  .video_showcase_text_heading{
    color: white;
    font-size: 2em;
  }
  .video_showcase_text_subheading{
    color: rgba(255, 255, 255, 0.5);
    font-size: 12px;
  }
 
  .video_showcase_video {
    width: 40% !important; /* Width of each video */
    height: fit-content !important; /* Height of each video */
    flex-shrink: 0; /* Prevent videos from shrinking */
    margin-left: 10px;
    aspect-ratio: 9/16;
    border-radius: 10px !important;
     /* Gap between videos */
  }
  
  /* Animation: Right-to-Left then Left-to-Right */
  @keyframes scrollRightToLeft {
    0% {
      transform: translateX(100%); /* Start at the far right */
    }
    100% {
      transform: translateX(-100%); /* Move to the far left */
    }
  }
  
  @keyframes scrollLeftToRight {
    0% {
      transform: translateX(-100%); /* Start at the far left */
    }
    100% {
      transform: translateX(70%); /* Move to the far right */
    }
  }
  
  .Video_showcase_mobile {
    animation: scrollRightToLeft 10s linear infinite alternate, scrollLeftToRight 10s linear infinite alternate;
  }
  
  
  
  .steps_box{
    padding: 20px;
    text-align: center;
    align-items: center;
    background-color: black;
    background: radial-gradient(circle at 45% 20%, #762bdeaf, transparent 40%), 
                radial-gradient(circle at 70% 90%, #36a0c793, transparent 40%);
  display: flex;
  flex-direction: column;
  gap: 40px;
  
  }
  
  .steps_title{
    font-size: 2em;
    background: linear-gradient(180deg, #762ADF 0%, #32A9C6 100%);
    -webkit-background-clip: text; 
    background-clip: text;
    color: transparent; 
    font-family: Outfit;
  }
  .steps_subtitle{
    color: white;
    font-size: 1em;    
    font-weight: 300;
  }
  
  .step_number{
  background: linear-gradient(180deg, #762ADF 0%, #32A9C6 100%);
  color: white;
  border: none;
  border-radius: 60%;
  font-size: 1.5em;
  height: fit-content;
  padding: 15px;
  width: fit-content;
  }
  .steps_text_title{
    color: white;
    font-size: 1.5em;
    width: 100%;
    
  }
  .steps_text_detail{
    font-size: 1em;
    color:  #FFFFFF80;
    width: 100%;
    text-align: center;
   
  }
   .steps_each_box{
    display: flex;
    flex-direction: row;
    gap: 20px;
    align-items: center;
  
  } 
  
  .all_steps{
    background-image: url(../../assets/rope.svg) ;
    background-repeat: no-repeat;
   
    background-position:center;
    display: flex;
    flex-direction: column;
  }
  
  
  .step3,.step1 {
    display: flex;
    flex-direction: column-reverse;
    gap: 40px;
    margin-bottom: 10vh;
  }
  .step2,.step4{
    display: flex;
    flex-direction: column-reverse;
    gap: 40px;
    margin-bottom: 10vh;
  }
  .steps_images{
    align-self: center;
    width: 100%;
    object-fit: cover; 
  }
  
  .subscription{
    background-color: #6423F7;
    align-items: center;
    text-align: center;
    padding: 40px;
    display: flex;
    flex-direction: column;
    gap: 20px;
  }
  .subscription_title{
    color: white;
    width: 50%;
    font-size: 1.5em;
  }
  .subscription_subtitle{
    color: white;
    width: 100%;
    font-size: 1em;
  }
  .plans_wrapper{
    background-color:transparent;
    border: none;
    border-radius: 20px;
    width: 100%;
    display: flex;
    flex-direction: column;
    padding: 10px;
    justify-content: center;
  }
  
  .plan-1,.plan-2,.plan-3{
    background-color: white;
  margin: 10px;
  display: flex;
  flex-direction: column;
  gap: 20px;
  text-align: left;
  border-radius: 10px;
  padding: 20px;
  max-width: 100%;
  }



  /* 
  {
    margin: 20px;
  display: flex;
  flex-direction: column;
  gap: 20px;
  text-align: left;
  background: linear-gradient(180deg, #762ADF 0%, #32A9C6 100%);
  border-radius: 20px;
  padding: 20px;
  max-width: 100%;
  }
  
  .price3{
    font-size: 2em;
    background: white;
    -webkit-background-clip: text; 
    background-clip: text;
    color: transparent; 
    font-family: Outfit;
    font-weight: bolder;
  } */
  .price1,.price2,.price3{
    font-size: 2em;
    background: linear-gradient(180deg, #762ADF 0%, #32A9C6 100%);
    -webkit-background-clip: text; 
    background-clip: text;
    color: transparent; 
    font-family: Outfit;
    font-weight: bolder;
  }
  .price_details{
    display: flex;
    flex-direction: row;
    
    align-items: center;
  
    
  }
  .price_interval,.price_interva2{
    color:#848199 ;
  }
  .price_interva3{
    color: white;
  }
  
  .discription{
    display: flex;
    flex-direction: column;
    gap: 10px;
  }
  
  .discription_subtitle{
    color: #848199;
  }
  
 
  .ponits_list{
    color: #52505f;
    display: flex;
    flex-direction: row;
    gap: 10px;
    
  }
  
  .points_list_full{
    display: flex;
    flex-direction: column;
    gap: 12px;
  }
  .choose_plan{
   background-color: #e7e1e1;
   font-size: 1.2em;
   color: #383838;
   border: none;
   border-radius: 20px; 
   padding: 5px;
   width: 70%;
   align-self:center;
  }
  .MOSTPOPULAR{
   background-color: white;
   color: linear-gradient(180deg, #762ADF 0%, #32A9C6 100%);
    border: none;
     border-radius: 20px;
     text-align: right;
     width: fit-content;
     padding: 8px;
    font-family: Outfit;
    font-weight: 800;
    justify-self: end; 
  }

  .footer_terms{
    display: flex;
    flex-direction: row;
    justify-content:space-around;
    font-size: 0.8em;
    color: white;
    font-family: Outfit;
    border-top:1px solid rgba(255, 255, 255, 0.24) ;
  }
  
  
  .footer_top{
    display: flex;
    flex-direction: column;
    gap: 30px;
    justify-content: center;
    justify-self: center;
    width: 100%;
    padding: 20px;
  }
  
  .form_footer{
    width: 100%;
    border-right:none !important;
    display: flex;
    flex-direction: column;
    gap: 5px;
  }
  .product_footer{
    border-right:none  !important;
    padding-right: 20px;
    display: flex;
    flex-direction: column;
    gap: 5px;
  }
  .contact_footer{
    
    padding-right: 20px;
    display: flex;
    flex-direction: column;
    gap: 5px;
  }
  
  .footer_headings{
    color: white;
    font-family: Outfit;
    font-size: 1.5em;
    font-weight: 600;
  }
  .footer_subheadings{
    color: white;
    font-size: 1em;
  }

}












/* BlogGrid.css */

/* Container for the blog grid */
.blog-container {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 30px;
  padding: 40px;
  justify-items: center; 
  align-items: center;
  background-color: transparent;
}

.blog-card {
  width: 300px;
  height: 340px;
  border: 1px solid  #32A9C6;
  border-radius: 8px;
  overflow: hidden;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
  background-color:#282828;
  color: white;
}

.blog-card img {
  width: 100%;
  height: 200px;
  object-fit: cover;
}

.blog-card .card-content {
  padding: 10px;
  flex-grow: 1;
}

.blog-card h3 {
  font-size: 18px;
  color: white;
  margin: 10px 0;
}

.blog-card p {
  font-size: 14px;
  color: white;
  line-height: 1.4;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

@media (max-width: 1140px) {
  .blog-container {
    grid-template-columns: 1fr;
  }
}





/* Styling for blogspage_top_blogsfull */
.blogspage_top_blogsfull {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 20px;
  padding: 20px;
  width: 70%;
  background-color: #282828;
  border: none;
  border-radius: 10px;
  margin: 20px auto; /* This ensures it centers horizontally within its parent */
}

.blogspage_top_content_blogsfull, .blogspage_top_image_blogsfull {
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
}

.blogspage_top_content_blogsfull {
  flex: 1;
  flex-direction: column;
  text-align: left;
  color: white;
}

.blogspage_top_image_blogsfull img {
  width: 50%;
  height: auto;
  object-fit: cover;
  border-radius: 10px;
}

/* Mobile responsiveness for smaller screens */
@media screen and (max-width: 1140px) {
  .blogspage_top_blogsfull {
      flex-direction: column;
      align-items: center;
      justify-content: center;
      padding: 10px;
      width: 95%;
  }

  .blogspage_top_content_blogsfull,
  .blogspage_top_image_blogsfull {
      flex: none;
      width: 100%;
  }


  .blog-page_blogsfull {
    padding: 8%;
    max-width: 100%;
    margin: 0 auto;
    background: #6423F7;
    display: flex;
    flex-direction: column;
    gap: 20px;
    color: white;
  
  }
  .blog-image-container_blogsfull {
    width: 100% !important;
    
    display: flex;
    justify-content: left;
  }

  .blog-title_blogsfull {
    font-size: 2.5rem;
   
  }
  .blog-title_blogsfull {
    font-size: 1.5rem !important;
    margin-bottom: 10px !important;
  }
/* Container for the pagination */
.pagination_blogspage {
  display: flex;
  flex-direction: row;
  justify-content: center;
  gap: 5px  !important;
  align-items: center;
  width: 100% !important;
}

/* Style for the individual page buttons */
.pagination_blogspage button {
  padding: 3px 8px !important; /* Make the button bigger */
  background-color: white; /* White background for the button */
  color: black; /* Black font color */
  border: none; /* Light border for the button */
  border-radius: 5px; /* Rounded corners */
  font-size: 12px !important; /* Adjust font size */
  cursor: pointer; /* Pointer cursor on hover */
   /* Smooth transitions */
}

/* Button hover effect */
.pagination_blogspage button:hover {
  background-color: #f1f1f1; /* Light grey on hover */
  transform: scale(1.05); /* Slightly scale the button on hover */
}

/* Active button style */
.pagination_blogspage button.active {
  background: #6423F7; /* Gradient for active button */
  color: white; /* White text on the active button */
  /* Border with the gradient color */
}

/* Disable button style */
.pagination_blogspage button:disabled {
  background-color: #f0f0f0; /* Disabled button with a light grey background */
  color: #aaa; /* Grey font for the disabled state */
  cursor: not-allowed; /* Show the not-allowed cursor */
}
}

/* Styling for blog-page_blogsfull */
.blog-page_blogsfull {
  padding: 8%;
  max-width: 100%;
  margin: 0 auto;
  background: #6423F7;
  display: flex;
  flex-direction: column;
  gap: 20px;
  color: white;

}

.blog-title_blogsfull {
  font-size: 2.5rem;
  margin-bottom: 20px;
}

.blog-image-container_blogsfull {
  width: 340px;
  margin: 20px 0;
  display: flex;
  justify-content: center;
}

.blog-image_blogsfull {
  width: 100%;
  height: auto;
  object-fit: cover;
}

.blog-content_blogsfull {
  font-family: Arial, sans-serif;
  line-height: 2  ;
  color: white;
  font-size: 1.1rem;
  margin-top: 20px;
}



/* Container for the pagination */
.pagination_blogspage {
  display: flex;
  flex-direction: row;
  justify-content: center;
  gap: 10px;
  align-items: center;
  margin-bottom: 20px;
  width: 80vw;
 
}

/* Style for the individual page buttons */
.pagination_blogspage button {
  padding: 10px 20px; /* Make the button bigger */
  background-color: white; /* White background for the button */
  color: black; /* Black font color */
  border: none; /* Light border for the button */
  border-radius: 5px; /* Rounded corners */
  font-size: 16px; /* Adjust font size */
  cursor: pointer; /* Pointer cursor on hover */
   /* Smooth transitions */
}

/* Button hover effect */
.pagination_blogspage button:hover {
  background-color: #f1f1f1; /* Light grey on hover */
  transform: scale(1.05); /* Slightly scale the button on hover */
}

/* Active button style */
.pagination_blogspage button.active {
  background: #762ADF ; /* Gradient for active button */
  color: white; /* White text on the active button */
  /* Border with the gradient color */
}

/* Disable button style */
.pagination_blogspage button:disabled {
  background-color: #f0f0f0; /* Disabled button with a light grey background */
  color: #aaa; /* Grey font for the disabled state */
  cursor: not-allowed; /* Show the not-allowed cursor */
}
