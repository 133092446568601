.generate-page-container {
    background-color: transparent;
    padding: 0px;
    border-radius: 8px;
  }
  
  .generate-topdiv {    
    padding-bottom: 0px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
    margin-top: 10px;
  }
  .doc_text{
    font-family: Outfit;
  }
  .backbtntext{
    display: flex;
    flex-direction: row;
   align-items: center;
   color:  var(--main-color);
   font-size: 1.2em;
  }
  .back-button {
 width: fit-content;
    color:  var(--main-color);    
    font-size: 1em;
    gap: 15px;
    border: none;
    background: none;
    cursor: pointer;
    display: flex;
    align-items: center; 
  }
  
  #generate-document-content {
    white-space: pre-wrap;
    word-wrap: break-word;
    font-size: 1em;
    margin-top: 0px;
    font-family: Outfit;
    font-weight: 500;
    padding-bottom: 20px;
    
  }
  
  .button-container {
    display: flex;
    flex-direction: row;
    gap: 60px;
    justify-content: flex-end;
    align-items: center;
    margin-top: 5px;
    
  }
  
  .action-button {
    background-color:  var(--main-color);
    color: #fff;
    border: none;
    padding: 10px 20px;
    cursor: pointer;
    border-radius: 5px;
  }
  .video-container_generatepage {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction:row;
    width: 100%;
    margin: 10px;
    height: fit-content;
  }
  
  .video_generatepage{
    width: 100%;
    height: fit-content;
    max-width: 100%;
    border-radius: 20px;
    overflow: hidden;  /* Apply rounded corners to video */
  }
 

  @media(max-width:600px){

    .delete-popup_historypreview {
      padding: 10% !important;
      padding-top: 80% !important;
    }
    
    .back-button {
      font-size: 1.2em;
      gap: 5px;
    }

    .generate-page-container {  
      
      padding-right: 5px;
      margin-bottom: 60px;
    }

    .button-container {
      height: 5vw;
      width: 20%;
      gap: 20px;
      justify-content: center;
    }
    .video_generatepage {
      max-width: 100% !important;
      height: fit-content !important;
    }
  }
  

  .notification {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: var(--main-color); /* Black background with transparency */
    color: white;
    padding: 10px 20px;
    border-radius: 5px;
    font-size: 16px;
    font-family: Outfit;
    z-index: 1000; /* Make sure it's on top of other elements */
    opacity: 0; /* Initially hidden */
    animation: showNotification 0.5s forwards; /* Fade in effect */
}

@keyframes showNotification {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}
/* Styles for the delete popup */
.delete-popup_historypreview {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: rgba(0, 0, 0, 0.9);;
  padding: 20px;
  border: 1px solid #ccc;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  z-index: 1000;
  width: 100%;
  height: 100%;
  padding: 40%;
}
.delete-popup_historypreview_white{
  background-color: var(--main-color);
  padding: 10%;
  border-radius: 15px;
}

.delete-popup_historypreview button {
  margin: 5px;
  padding: 10px;
  background-color:white;
  color: var(--main-color);
  border: none;
  cursor: pointer;
  border-radius: 5px;
}


/* Styles for delete result notification inside the popup */
.delete-result {
  text-align: center;
  font-size: 16px;
  padding: 10px;
  margin-top: 10px;
}

.delete-result.success {
  color: green;
}

.delete-result.failure {
  color: red;
}
