.title_buy_premium{
  display: flex;
    flex-direction: row;
    row-gap: 15px;

}
#modal-button{
     padding: 10px;
     margin-right: 5px;
     background-color: var(--main-color);
     border: none;
     border-radius: 10px;
     color: white;

}

.discountedprice{
   font-weight: 500;
   color: gray;
   text-decoration: line-through;
}
  .left-section {
    flex: 1;
    text-align: left;
    padding-right: 40px;
  }

  @media(max-width:600px){
    .proicon-buypage{
      height: 1.5vh !important;
      margin-top: 3px;
    }
  
  }

  @media(max-width:399px){
  
  .title_buy_premium{
    font-size: 1.1em !important;
  }
  .proicon-buypage{
    height: 2vh !important;
  }
  .left-section{
    padding-right: 0px !important;
  }
  
  }
   
  .title_buy_premium{    
    margin-top: 20px;
    font-family:Outfit; 
    font-size: 1.2em;
    line-height: 35px;
    font-weight: 700;
  }
  /* Add this to your Buy.css */
.loadingspinner {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100px; /* Adjust based on your layout */
}


  .righticon{
    height: 1.5vh;
  }

  .titlethree{
    font-family:Outfit; 
    font-weight: 500;
    font-size: 1.2em;

  }
  .pointbuy{
    font-family:Outfit; 
    font-weight: 400;
    margin-top: 10px;
  font-size: 0.8em;  }

  .proicon-buypage{
    height: 2vh;   
    justify-self: center;
    margin-left: 5px;
  }

  .secondndtitle{
    font-family: Outfit;
    font-weight: 600;

  }
  h2{
    font-family: Outfit;
    font-weight: 700;
    
  }
  
  .pro-label {
    background-color:var(--main-color);;
    color: white;
    padding: 2px 6px;
    border-radius: 4px;
    font-weight: bold;
  }
  
  .features {
    margin: 15px 0;
  }
  
  .features ul {
    list-style-type: none;
    padding: 0px;
    row-gap: 10px;
    width: 99%;
   
   
  }
  .features li{
    gap: 10px;
  }
  
  .features ul li {
    margin: 15px 0;
    
  }
  
  .plans-wrapper {
    display: flex;
    flex: 1;
    justify-content: center;
    gap: 20px;   
    
   
  }
  .modal {
    position: fixed;
    z-index: 1000;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
}

.modal-content {
    background-color: white;
    padding: 20px;
    border-radius: 5px;
    text-align: center;
    width: 200px;
  
}
.modal-img{
  color:  var(--main-color);
  font-size: 20px;
  font-family: Outfit;
  height: 80px;
  width: 80px;
 
}
.modal-msg{
  font-family: Outfit;
}

#moadal-button{
   margin-right: 5px;
   background-color: var(--main-color);
   border: none;
   padding: 10px;
   color: white;
   border-radius: 10px;
   width: 70px;
}



.close-modal {
    cursor: pointer;
    float: right;
    size: 2em;
}

.modal-icon {
    margin-bottom: 10px;
    
}

  
.loading-spinner {
  border: 8px solid transparent; /* Make the base transparent */
  border-top: 8px solid var(--main-color); /* Red color */
  border-radius: 50%;
  width: 60px; /* Slightly bigger spinner */
  height: 60px; /* Slightly bigger spinner */
  animation: spin 1.2s linear infinite; /* Slightly slower for a smoother effect */
  margin: 20px auto; /* Center it */
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2); /* Light shadow to add depth */
  position: relative;
  z-index: 1;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

/* Add a cool gradient effect behind the spinner */
.loading-spinner:before {
  content: '';
  position: absolute;
  top: -10px;
  left: -10px;
  width: 100%;
  height: 100%;
  border-radius: 50%;
  /* Gradient for extra flair */
  z-index: -1; /* Ensure the gradient stays behind the spinner */
  animation: pulse 1.5s ease-in-out infinite; /* Pulsing effect for the gradient */
}

@keyframes pulse {
  0% {
      transform: scale(1);
      opacity: 0.6;
  }
  50% {
      transform: scale(1.1);
      opacity: 0.8;
  }
  100% {
      transform: scale(1);
      opacity: 0.6;
  }
}

  .plan_content{    
    padding-left: 20px;
    padding-top: 10px;
    width: 200px;  
    text-align: left;
  }
  .plan-box-1 {
    border: 2px solid lightgray;
    border-radius: 10px;
    height: fit-content;
    min-height: 180px;
   
  }
  .plan-box-2 {
    border: 2px solid lightgray;
    border-radius: 10px;
    height: fit-content;
    min-height: 180px;
  }

 
  
  

  
  .plan-box-1.selected,  
  .plan-box-2.selected {
    border-color:  var(--main-color); 
  
    cursor: pointer; 
  }

  .fullscreen-loader {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.7);
    z-index: 9999; 
    display: flex;
    justify-content: center;
    align-items: center;
}
  .plan-box-1.selected
   {
    .tag_plan-1{  

    display: none;
    }
  
  }


  .plan-box-2.selected{

    .tag_plan-2{      
      display: none;
  
      }
     
  }
  .plan-box h3 {
    font-size: 24px;
    margin: 10px 0;
  }
  
  .plan-box p {
    margin: 5px 0;
  }
  
  .tag_plan-1{
    font-weight: bold;
    display: inline-flex;
    margin-bottom: 10px;
    color: white;
    background-color:  var(--main-color);
    border-radius: 5px;
    font-family:Outfit; 
    font-weight: 400;
    padding: 5px;
  }
  .tag_plan-2{
    font-weight: bold;
    display: inline-flex;
    margin-bottom: 10px;
    color: white;
    background-color:  var(--main-color);
    border-radius: 5px;
    font-family:Outfit; 
    font-weight: 400;
    padding: 5px;
  }

  
  .plans_text{
    font-family:Outfit;
    margin: 10px;
  }
  
  .one-time-offer {
    font-weight: bold;
    display: block;
    margin-bottom: 10px;
    color: red;
  }
  
  .price-info {
    margin-top: 20px;
    text-align: center;
    font-size: 1em;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .buy-button-img{
    width: 2vw;
  }
  
  .buy-button {
   background-color: var(--main-color);
   color: white;
   padding-top: 12px;
   padding-bottom: 12px;
   border: none;
   border-color: white;
   width: 35%;  
   border-radius: 50px;
   align-items: center;
   justify-content: center;
   display: flex;
      flex-direction: row;
      gap: 20px;
   
  }
  .error-location{
    text-align: center;
    color: #f3f3f3;
    background-color:  var(--main-color);
  }
  
  .no-plans{
    text-align: center;
  }
 
  

  @media (max-width: 600px) {
    .proicon{
      height: 2.9vh; 
      align-items: center;
    }
    
    h2{
      font-family: Outfit;
      font-weight: 700;
      width: 70vw;
      font-size: 1.3em;
      
      
      
    }
    .price-info {
      margin-top: 20px;
      text-align: center;
    }
    
    .buy-button-img{
      width: 6vw;
    }

    .buy-button {
      align-items: center !important;
      box-sizing: border-box;
       padding-top:15px ;
       padding-bottom: 15px;
      width: 100% !important;
      margin-top: 10px; 
      margin-bottom: 10px; 
      font-size: 16px;
      font-family: Outfit;
      display: flex;
      flex-direction: row;
      gap: 20px;
    }
    .plans-wrapper {
      display: flex;
      flex: 1;      
      flex-direction: column;
      justify-content: center;
      column-gap: 10px;   
     
    }
    .left-section {
       display: block;
       flex-direction: column;
      text-align: left;
      padding-right: 40px;
      margin-bottom: 5px;
    }
    .price-info {
      margin-top: 20px;
      text-align: center;
      display: flex;
      flex-direction: column;
    }
    .plan-box-1 {
      border: 2px solid lightgray;
      border-radius: 10px;
      height: 10px !important;
      min-height: 150px !important;
     
    }
    .plan-box-2 {
      border: 2px solid lightgray;
      border-radius: 10px;
      height:10px !important;
      min-height: 150px !important;
    }

  }
  @media (max-width: 375px) {
    
    .plans-wrapper {
      display: flex;
      flex: 1;      
      flex-direction: column;
      justify-content: center;
      column-gap: 10px;   
     
    }
    .left-section {
       display: block;
       flex-direction: column;
      text-align: left;
      padding-right: 40px;
      margin-bottom: 5px;
    }
    .price-info {
      margin-top: 20px;
      text-align: center;
      display: flex;
      flex-direction: column;
    }
    
    .plan-box-1 {
      border: 2px solid lightgray;
      border-radius: 10px;
      height: 5px !important;
      min-height: 150px !important;
     
    }
    .plan-box-2 {
      border: 2px solid lightgray;
      border-radius: 10px;
      height:5px !important;
     min-height: 150px !important;
    }

  }
  .back-button-buypage { 
    color: #8d12ff;
    width: 2em;   
    gap: 15px;
    margin-right: 10px;
    border: none;
    background: none;
    cursor: pointer;
    display: flex;
    align-items: center; 
    margin:5px;
  }
  
  .paypal-popup {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.8);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000; /* Ensure it sits above other content */
}

.popup-content {
    background: white;
    padding: 20px;
    border-radius: 8px;
    text-align: center;
}

.close-button {
    margin-top: 15px;
    padding: 10px 20px;
    border: none;
    background-color: #f44336; /* Red color for cancel button */
    color: white;
    cursor: pointer;
    border-radius: 4px;
}


.subscription_BuyPage{
  background-color: #6423F7;
  align-items: center;
  text-align: center;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  gap: 10px;
}
.subscription_title_BuyPage{
  color: white;
  width: 60%;
  font-size: 1.5em;
}
.subscription_subtitle_BuyPage{
  color: white;
  width: 80%;
  font-size: 2em;
}
.plans_wrapper_BuyPage{
  background-color: white;
  border: none;
  border-radius: 20px;
  width: 90%;
  display: flex;
  flex-direction: row;
  padding: 10px;
  justify-content: center;
}

.plan-1_BuyPage,.plan-2_BuyPage,.plan-3_BuyPage{
  margin: 20px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 20px;
  text-align: left;
  border-radius: 10px;
  padding: 20px;
  max-width: 350px;
}





.discription_subtitle1_BuyPage,.discription_subtitle2_BuyPage,.discription_subtitle3_BuyPage{
  color: black;
}
.price1_BuyPage,.price2_BuyPage,.price3_BuyPage{
  font-size: 2em;
  background: linear-gradient(180deg, #762ADF 0%, #32A9C6 100%);
  -webkit-background-clip: text; 
  background-clip: text;
  color: transparent; 
  font-family: Outfit;
  font-weight: bolder;
}
.white_check1_BuyPage,.white_check2_BuyPage,.white_check3_BuyPage{
  display: none !important;
}
.price_details_BuyPage{
  display: flex;
  flex-direction: row;
  align-items: center;  
}
.price_interval1_BuyPage,.price_interval2_BuyPage,.price_interval3_BuyPage{
  color:#848199 ;
}


.discription1_BuyPage,.discription2_BuyPage,.discription3_BuyPage{
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin-bottom: 10px;
}

.discription_subtitle_BuyPage{
  color: black;
}

.ponits_list1_BuyPage,.ponits_list2_BuyPage,.ponits_list3_BuyPage{
  color: #848199;
  display: flex;
  flex-direction: row;
  gap: 10px;
}

.points_list_full_BuyPage{
  display: flex;
  flex-direction: column;
  gap: 12px;
}


.choose_plan1_BuyPage,.choose_plan2_BuyPage,.choose_plan3_BuyPage{
  background-color: #e7e1e1;
  font-size: 1.2em;
  color: #8d8d8d;
  border: none;
  border-radius: 20px; 
  padding: 5px;
  width: 200px;
  align-self:center;
}

.MOSTPOPULAR_BuyPage{
  background-color: white;
  color: linear-gradient(180deg, #762ADF 0%, #32A9C6 100%);
  border: none;
  border-radius: 20px;
  text-align: right;
  width: fit-content;
  padding: 8px;
  font-family: Outfit;
  font-weight: 800;
  justify-self: end; 
}


@media (max-width: 1140px) {
  .ponits_list1_BuyPage,.ponits_list2_BuyPage,.ponits_list3_BuyPage{
    color: #848199;
    display: flex;
    flex-direction: row;
    gap: 5px;
   font-size: 12px;
  }
  
  .discription_subtitle1_BuyPage,.discription_subtitle2_BuyPage,.discription_subtitle3_BuyPage{
    color: black;
    font-size: 1em;
  }

  .discription1_BuyPage,.discription2_BuyPage,.discription3_BuyPage{
    display: flex;
    flex-direction: column;
    gap: 5px;
    margin-bottom: 5px;
  }
  .subscription_BuyPage{
      background-color: #22222A;
      align-items: center;
      text-align: center;
      display: flex;
      flex-direction: column;
      gap: 10px;
      margin-bottom: 20px;
  }

  .subscription_title_BuyPage{
      color: white;
      width: 50%;
      font-size: 1.5em;
  }

  .subscription_subtitle_BuyPage{
      color: white;
      width: 100%;
      font-size: 1em;
  }

  .plans_wrapper_BuyPage{
      background-color: transparent;
      border: none;
      border-radius: 20px;
      width: 100%;
      display: flex;
      flex-direction: column;
      padding: 0px;
      justify-content: center;
      margin-bottom: 10px;
  }

  .plan-1_BuyPage, .plan-2_BuyPage, .plan-3_BuyPage{
      background-color: white;
      margin: 10px;
      display: flex;
      flex-direction: column;
      gap: 10px;
      text-align: left;
      border-radius: 10px;
      padding: 10px 20px;
      max-width: 100%;
  }

  .price1_BuyPage, .price2_BuyPage, .price3_BuyPage{
      font-size: 1.2em;
      background: linear-gradient(180deg, #762ADF 0%, #32A9C6 100%);
      -webkit-background-clip: text; 
      background-clip: text;
      color: transparent; 
      font-family: Outfit;
      font-weight: bolder;
  }

 
  
  .price_details_BuyPage{
      display: flex;
      flex-direction: row;
      align-items: center;
  }

  .price_interval_BuyPage, .price_interva2_BuyPage{
      color: #848199;
  }

  .price_interva3_BuyPage{
      color: white;
  }

  .discription_BuyPage{
      display: flex;
      flex-direction: column;
      gap: 10px;
  }

  .discription_subtitle_BuyPage{
      color: #848199;
  }

  .ponits_list_BuyPage{
      color: #848199;
      display: flex;
      flex-direction: row;
      gap: 10px;
  }

  .points_list_full_BuyPage{
      display: flex;
      flex-direction: column;
      gap: 8px;
  }

  .choose_plan_BuyPage{
     background-color: #e7e1e1;
     font-size: 1.2em;
     color: #8d8d8d;
     border: none;
     border-radius: 20px; 
     padding: 5px;
     width: 70%;
     align-self: center;
  }

  .MOSTPOPULAR_BuyPage{
     background-color: white;
     color: linear-gradient(180deg, #762ADF 0%, #32A9C6 100%);
     border: none;
     border-radius: 20px;
     text-align: right;
     width: fit-content;
     padding: 8px;
     font-family: Outfit;
     font-weight: 800;
     justify-self: end;
  }
}
