.tab-container {
    height: fit-content;
    -webkit-overflow-scrolling: touch;
    width: 100%;
    max-width: 100%; /* Ensure container doesn’t overflow */
  overflow-x: auto; /* Enable horizontal scroll */
  white-space: nowrap; /* Prevent wrapping of buttons */
  box-sizing: border-box; /* Include padding/border in element's total width */
  padding: 10px 0;
  
}
.tab-container::-webkit-scrollbar {
    display: none;
  }
  .tabs_btn{
    color: white;
    display: flex;
    background-color: #0E0220;
    border-radius: 12px;
    font-family: 'Outfit', sans-serif;
   width: fit-content;
  }
  /* For Firefox (uses scrollbars based on the system's preferences) */
  .tab-container {
    scrollbar-width: none; /* Hide scrollbar in Firefox */
  }
  
.tab-container button {
    display: inline-flex; /* Make buttons appear inline horizontally */
    /* Optional: Add space between buttons */
    white-space: normal; /* Ensure button text doesn’t overflow */
    justify-content: center;
    align-items: center;
    text-align: center;
  }

@media(max-width:391px){
    .tabs-btn { 
        font-size: 14px !important;
    }

}
@media(max-width:361px){
    .tabs-btn { 
        font-size: 12px !important;
    }

}
.tabs-btn { 
    flex-shrink: 0; /* Prevents buttons from shrinking */
    padding: 10px;
    margin: 10px;
    background-color: transparent;
    border: 2px solid transparent;
    border-radius: 12px;
    color: white;
    cursor: pointer;
    transition: background-color 0.3s, border-color 0.3s;
    text-align: center;
   width: fit-content;
   min-height: 30px; /* Ensures tabs don't shrink too small */
}

.tabs-icon {
    margin-right: 5px;              
    transition: filter 0.3s;      
}

.tabs-btn.active {
    background-color: #5D0AD1;
    border-radius: 5px;
}




@media (max-width: 600px) {
    .tab-container {
        margin-top: 20px !important;
        width: 100%;
    }

    .tabs-btn {

        display: inline-flex;                   
        align-items: center;
        background-color: transparent;
  
        font-family: 'Outfit', sans-serif;
        font-size: 1em;
        font-weight: 600;
        color: #9AA4B8;        
        height: 20px;                
        border: none;    
        transition: color 0.3s, border-color 0.3s;  
    }
}
