.Historypagegrp{
  display: flex;
  flex-direction: row;
  gap: 10px;
  flex-wrap: wrap;
}
.repeating-group {
  padding: 10px;
  border: 1px solid #5D0AD1;
  border-radius: 15px; 
  display: flex;
  flex-direction: column; 
 margin-bottom: 10px;
 overflow: hidden;
 height: fit-content;
 
}

.video-thumbnail {
  flex: 0 0 30%; /* Video thumbnail takes 30% of the width */
  width: 100%;
  
}

.video-thumbnail video {
  width: 100%;
   max-height: 80px;
  border-radius: 15px;
}

.text-container {
  flex: 1; /* Takes the remaining space */
  display: flex;
  flex-direction: column;
  text-align: left;
  justify-content: space-between;
  padding: 10px;
}

.text-container p {
  margin: 0;
  overflow: hidden;
  text-overflow: ellipsis;
/* Show only 3 lines */
  -webkit-box-orient: vertical;
}

.icon-container {
  display: flex;
  gap: 10px;
  margin-top: 10px;
}
.delete_btn_history{
  height: 5vh;
  color: #5D0AD1;
  background-color: #FCF2E8;
  border: none;
  padding: 10px 10px;
  cursor: pointer;
  border-radius: 5px;
  display: flex;
  gap: 10px;
  align-items: center;
  width: fit-content;
}
.icon-container img {
  width: 20px;
  height: 20px;
  cursor: pointer;
}

.notificationCopy {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: var(--main-color);
  color: white;
  padding: 10px 20px;
  border-radius: 5px;
  font-size: 16px;
  z-index: 1000;
  opacity: 0;
  animation: showNotificationCopy 0.5s forwards;
}
@media(max-width:600px){
  .delete-popup {
    position: fixed; /* Use fixed positioning to make it stay on screen even when scrolling */
    top: 50% !important; /* Center vertically */
    left: 50%; /* Center horizontally */
    transform: translate(-50%, -50%); /* Adjust the element's position to exactly center it */
    background-color: rgba(0, 0, 0, 0.7);
     padding-top: 80% !important;/* Semi-transparent background */
    width: 100%;
    height: 100%; /* Set a fixed width for the popup */
    padding-left: 10% !important;
    padding-right: 10% !important;
    border-radius: 8px;
    z-index: 1000; /* Ensure it appears above other content */
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    background-attachment: fixed;
    
  }
  .video-thumbnail video {
    width: 100%;
     max-height:180px !important;
    border-radius: 15px;
    background-color: rgba(0, 0, 0, 0.39);
  }

  .Historypagegrp{
    display: flex;
    flex-direction: column;
    gap: 20px;
    width: 100%;
  }
  
.video-thumbnail {
  flex: 0 0 30%; /* Video thumbnail takes 30% of the width */
  width: 100%;
  
}
}
@keyframes showNotificationCopy {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
/* Styles for the delete popup */
.delete-popup {
  position: fixed; /* Use fixed positioning to make it stay on screen even when scrolling */
  top: 50%; /* Center vertically */
  left: 50%; /* Center horizontally */
  transform: translate(-50%, -50%); /* Adjust the element's position to exactly center it */
  background-color: rgba(0, 0, 0, 0.9);
   /* Semi-transparent background */
  width: 100%;
  height: 100%; /* Set a fixed width for the popup */
  padding: 20%;
  border-radius: 8px;
  z-index: 1000; /* Ensure it appears above other content */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  background-attachment: fixed;
  
}

.delete-popup-content {
  background-color:var(--main-color);
  padding: 20px;
  border-radius: 8px;
  text-align: center;
}

.delete-popup .popup-buttons {
  margin-top: 20px;
}

.delete-popup button {
  padding: 10px 20px;
  margin: 0 10px;
  background-color: white;
  color: var(--main-color);
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.delete-popup button:disabled {
  background-color: black;
}

.delete-result {
  margin-top: 20px;
  font-size: 16px;
}

.delete-result.success {
  color: green;
}

.delete-result.error {
  color: red;
}
/* Full-screen overlay */
.loader-history {
  position: relative;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999;
}



/* Spinning animation */
@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

/* Pulsing animation for crazy effect */
@keyframes pulse {
  0% {
    transform: scale(1);
    opacity: 1;
  }
  50% {
    transform: scale(1.2);
    opacity: 0.7;
  }
  100% {
    transform: scale(1);
    opacity: 1;
  }
}

/* Text below the loader */
.loader-history .loader-text {
  margin-top: 20px;
  color: var(--main-color);
  font-size: 18px;
  font-family: 'Arial', sans-serif;
  animation: textBounce 1s infinite alternate;
}

/* Bouncing text effect */
@keyframes textBounce {
  0% {
    transform: translateY(0);
  }
  100% {
    transform: translateY(-10px);
  }
}

