.generate-page-container {
  background-color: transparent;
  padding: 20px;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  align-items: center;
  height: fit-content !important;
}

.generate-topdiv {
  display: flex;
  justify-content: flex-start; /* Align the back button to the left */
  width: 100%;
  margin-bottom: 10px; /* Space below the back button */
}

.backbtntext {
  display: flex;
  align-items: center;
  color: var(--main-color);
  font-size: 1.2em;
}

.back-button {
  background: none;
  border: none;
  cursor: pointer;
}

.title-box_generatepage {
  display: flex !important;
  justify-content: space-between !important;
  width: 100%;
  margin-bottom: 20px; 
  align-items: center;/* Space between the title box and content below */
}

.title-genearate {
  font-size: 1.5em;
  font-weight: bold;
  width: fit-content;
  margin-left: 1em;
  color: white;
}

.button-container_generatepage {
  height: fit-content;
   /* Align buttons to the right */
   display: flex;
   flex-direction: row;
  gap: 20px;
  width: fit-content;
}
.btn_img_generatepage{
height: 3vh;
}
.action-button_generatepage {
  height: 5vh;
  color: #5D0AD1;
  background-color: #FCF2E8;
  border: none;
  padding: 10px 10px;
  cursor: pointer;
  border-radius: 5px;
  display: flex;
  gap: 10px;
  align-items: center;
  width: fit-content;
}

.action-button img {
  width: 20px;
  margin-right: 10px;
}

.instruction-text_generatepage {
  font-size: 1em;
  margin-bottom: 20px;
  text-align: left;
  padding: 0 20px;
  color: white;
}

.story-container {
  width: 100%;
  padding: 0 20px;
  margin-bottom: 20px;
}

#generate-document-content {
  padding-top: 10px;
  padding-left: 20px;
  padding-right: 20px;
  border-radius: 5px;
  white-space: pre-wrap;
  word-wrap: break-word;
  font-size: 1em;
  line-height: 1.2;
  margin-top: 0px;
  font-family: Outfit;
  font-weight: 500;
  padding-bottom: 20px;
  color: black;
}

.video-container_generatepage {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction:row;
  width: 100%;
  margin: 10px;
  height: fit-content;
}

.video-container_generatepage h2 {
  text-align: center;
  margin-bottom: 10px;
  overflow: hidden; 
}

.video_generatepage {
  width: 100%;
  height: fit-content;
  max-width: 100%;
  border-radius: 20px;
  overflow: hidden;  /* Apply rounded corners to video */
}

/* Media queries for responsiveness */
@media (max-width: 600px) {
  .delete-popup_generatepage {
    padding: 10% !important;
    padding-top: 80% !important;
  }
  .title-genearate {
    font-size: 0.9em;

  }
  .generate-topdiv {
    flex-direction: column;
    align-items: flex-start;
  }

  .button-container_generatepage {
    flex-direction: row;
    gap: 10px;
    align-items: flex-start;
  }

  .action-button_generatepage {
    width: 100%; /* Make buttons full width on smaller screens */
    padding: 10px;
    height: 4vh;
  }

  .instruction-text_generatepage {
    font-size: 0.9em;
    padding: 0 10px;
  }

  .video_generatepage {
    max-width: 100% !important;
    height: fit-content !important;
  }
  .btn_img_generatepage{
    height: 2vh;
    }
}
/* Delete confirmation popup */
.delete-popup_generatepage {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color:rgba(0, 0, 0, 0.9);;
  border-radius: 8px;
  padding: 25%;
  box-shadow: 0 4px 6px var(--main-color);
  z-index: 1000; /* Ensure the popup is above other elements */
  text-align: center;
  width: 100%;
  height: 100%;
}
.delete-popup_generatepage_white{
  background-color: white;
  border-radius: 20px;
  padding: 10%;
}

.delete-popup_generatepage p {
  font-size: 18px;
  margin-bottom: 20px;
  color: #333;
}

.delete-popup_generatepage button {
  background-color: var(--main-color);
  color: white;
  border: none;
  padding: 10px 20px;
  margin: 5px;
  cursor: pointer;
  font-size: 16px;
  border-radius: 5px;
  transition: background-color 0.3s;
}


.delete-popup_generatepage button:last-child {
  background-color: #ccc;
  color: #333;
}

/* Overlay effect when popup is visible */
.delete-popup-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 999;
}

/* loader Css*/
/* Loader Styling */
.loader-container {
  color: white !important;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background-color: #fff;  /* You can change this to match your theme */
}

.loader {
  border: 8px solid #f3f3f3; /* Light grey */
  border-top: 8px solid #3498db; /* Blue */
  border-radius: 50%;
  width: 60px;
  height: 60px;
  animation: spin 1.5s linear infinite;
}

.loader-message {
  margin-top: 20px;
  font-size: 16px;
  color: white !important;
  font-weight: 600;
  text-align: center;
}

/* Spinner animation */
@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}
