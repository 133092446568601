*{   
    box-sizing: border-box;
    font-family: Outfit;
    padding: 0px;
    margin: 0px;
    
}

/* body{
  background-color: #EDEDF2;
  padding-left: 20px;
  padding-right: 20px;
  padding-top: 20px;


  background-image: url('../assets/appbackground.svg');
  background-position: center;   
  background-size: cover;       
  background-repeat: no-repeat;  
  background-attachment: fixed;  
  
  
} */

body{
  background-color: black !important;
  background: radial-gradient(circle at 25% 20%, #762bdeaf, transparent 20%), 
  radial-gradient(circle at 95% 30%, #36a0c793, transparent 30%);

}
.main_panel.generation {
    flex: 0 1 auto;
    margin-top: 10px !important;  
    margin-left: 22%;  
    width: 70%;
    height: fit-content !important;
    min-height: 90vh;
    border-radius: 10px;
    padding-left: 20px;
    padding-top: 15px!important ;
    padding-right: 20px;
}

.buttonscrollonslider{
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-left: 30px;
    gap: 20px;
}
.error {
    border: 1px solid red !important; 
  }
  
  .error-message {
    color: red; /* Error message color */
    font-size: 12px; /* Adjust font size */
    margin-top: 5px; /* Spacing */
    text-align: left;
font-family: Outfit ;}
@media(max-width:390px){

.h1{
    width: 100%;
    font-size: 1em;
}
.proicon{
    height: 2vh !important;
}



    }

    @media(max-width:360px){

        .h1{
            width: 100%;
            font-size: 1em;
        }
        
        
        
            }


            @media(max-width:360px){

                .main_panel.generation{
                    padding-left: 4px !important;
                    padding-right: 4px !important;
                }  
                
               
                }

@media(max-width:900px){
    
    body{
        padding: 0 !important;
        margin: 0px !important;
    }
    .username_text{
        display: none !important;
    }
    .topic{
        margin-top: 10px;
    }
   
    .main_panel{
        margin: 15px !important;
        padding-top: 0px !important;
        padding-left: 25px !important;
        padding-right: 25px !important;
        width: 92% !important; 
        min-height: 50vh !important;
         
    }
    .buy-button_content{
        width: 90% !important;
    }
    
   
    .buttonText{
        display: none !important;
    }
    .proicon{
        width: 8vw !important; 
        align-items: center;
      }
    .main_panel.generation{
        padding-left: 8px !important;
        padding-right: 6px !important;
    }
    .left_panel{
        
        display:none !important ; /* Hide the element */
    }
    
    .div_input{
        flex-direction: column;
        margin-top: 15px !important;

    }
    .header_mobile{
        display: flex !important;
        
    }
  

    .title_name {
        font-size: 1em !important ; 
        margin-bottom: 0px !important;
        display: flex;
        align-items: center;
        color: white;
        gap: 10px;
    }

    .username_text {
        font-size: 1em !important; /* Adjust font size for smaller screens */
        margin-top: 0.5rem; /* Add some space above the text */
    }

    .title_main_page {
      font-size: 20px;
        margin-bottom: 10px !important;
        flex-direction: column; /* Stack items vertically */
        align-items: flex-start !important; /* Align items to the start */
        text-align: left; 
        align-items: center !important;/* Ensure text is left-aligned */
        gap: 5px;
    }
   
   
.logo_title{
    display: inline-block!important;
    height: 5vh;    
}


.input_1{
    font-family: Outfit;
    font-weight: 400;
    margin-bottom: 5px;   
    height: 100px !important;
    border-radius: 8px;
    background-color: transparent;
    border: none;
    padding: 10px;
    font-size: 0.8em;
    color: white !important;
    
}
.input_1::placeholder {
    color: rgba(143, 138, 138, 0.377); /* Change the color and adjust opacity */
}

.input_2{
    width: 100% !important;
    height: 7vh;
    border-radius: 8px;
    background-color: #f8f8ff;
    border: none;
    padding: 10px;
    box-sizing: border-box;
    color:rgba(143, 138, 138, 0.377) ;
}
.input_2::placeholder {
  color: rgba(143, 138, 138, 0.377); /* Change the color and adjust opacity */
}


.div_input{
    margin-top: 10px;
    display: flex !important;
    flex-direction: column;
    gap: 30px !important;
    
}

.generate_btn{
    width: 100% !important;
    margin-top: 40px; 
    margin-bottom: 40px;  
     
}




}

.no-scroll {
    overflow: hidden !important;
}


.header_mobile{
    background-color: var(--main-color) ;    
    height: 8vh;
    display: none;
    justify-content: space-between; 
    align-items: center; 
    padding: 15px;

}
#login_header{
    font-size: 0.8em;
    font-family:Outfit; 
    color: white;
    border: 2px solid white;
    border-radius:150px;
    background-color: transparent;
    padding: 3px;
    padding-left: 10px;
    padding-right: 10px;
}
#logout_header{
    font-size: 0.7em;
    font-family:Outfit; 
    color: white;
    border: 2px solid white;
    border-radius:150px;
    background-color: transparent;
    padding: 5px;
    padding-left: 10px;
    padding-right: 10px;

}


.logo_left_panel{
    width:5.3vw;
    
    
}

.login_crown_container{
    font-family:Outfit; 
    width: 100px;
    display: grid;
    grid-template-columns: auto auto; /* Two columns */
    align-items: center; 
    gap: 5px;
}

.left_panel{
    height: fit-content;
    position: fixed;
    width: 19%;
    border-radius: 10px;
    margin-left: 20px;
    margin-top: 20px;
    padding-top: 20px;  
    display: flex;
    flex-direction: column; 
    gap: 5px;  
    align-items: center;

    background-image: url('../assets/left_background.svg');
    background-position: center;   /* Centers the background image */
          /* Ensures the image covers the div, resizing to fit */
    background-repeat: no-repeat;  /* Prevents repeating the image */

    
    }

.left_title {
    margin-top: 10px;
    margin-bottom: 5px;
    font-family:Outfit;
    font-size: 1.3vw;
    color: white;
    font-weight: 700;
}

.buy_btn {
    width: 50px ;
    margin-bottom: 10px;
}
.buy-btn-img-left-panel {
    max-width: 65vw;
    width: 16vw;
    border-radius: 50px;
    border: none;
    padding: 8px;
    background-color: white;
    color: var(--main-color);    
    height: auto; 
    display: flex;
    flex-direction: row;
    gap: 10px;
    align-items: center;
    justify-content: center;
    font-family: Outfit;
    font-size: 1vw;
    
  }
  .panelcrown{
    width: 1.5vw;
  }

.scroll_btn{
    justify-self: left;
    background-color: transparent;
    text-align: left;
    color: #DADDDE;
    border: none;
    font-size:1.4vw;
    display: flex;
    justify-content: left;
    align-items: center;      
    gap:10px;
    height: 5%;
    transition: color 0.3s;
    cursor: pointer;
    margin-top: 10px;
    width: 100%;
    
}

.scroll-img{
    width: 2vw;
}

.scroll_btn:hover {
    color: white; 
}

.scroll_btn:hover .scroll-img{
    filter: brightness(0) invert(1); 
}

.scroll_btn.active .scroll-img{
    filter: brightness(0) invert(1); 
}

.scroll_btn.active {
    color: white;
    border-right: 2px solid white;
   
    
}

#login_left{
  margin-top: 40%;
  width: 10vw;
  background-color: transparent;
  color: white;
  overflow: hidden; 
  white-space: nowrap; 
  margin-bottom: 120px;
  font-size: 1em;
    font-family:Outfit; 
    border: 2px solid white;
    border-radius:150px;
    background-color: transparent;
    padding: 5px;
    padding-left: 20px;
    padding-right: 20px;
}

.buy_btn_left{
   
    height:10% ;
    background-color: transparent;
    border: none;
    text-align: left;
}

.main_panel{    
    flex: 0 1 auto;
    margin-top: 0px;  
    margin-left: 22%;  
    margin-right: 50px;
    margin-top: 20px;
    width: 70%;
    height: fit-content !important;
    min-height: 90vh;
    background-color: #22222A;
    border-radius: 10px;
    padding-left: 50px;
    padding-top: 15px;
    padding-right: 60px;
    color: white;
    
    
}
#container {
    display: flex;
    justify-content:space-between; 
    padding: 0;
    margin: 0;
    
}


.logo_title{
    display: none;


}
.proicon{
    width: 4vw;
}
.title_main_page {
    margin-top: 20px;
    margin-bottom: 25px;
    height:30px;
    width: 100%;
    display: flex;
    flex-direction: row;
    gap: 20px;
    justify-content:left; 
    align-items: center;  }

.title_name{
  color: white;
    margin-bottom: 10px;
    font-family:Outfit;
    font-size: 2.6vw;
    font-weight: 700;
    height: fit-content;
}   

.username_text {
    font-family: Outfit;
    font-size: 1vw;
    color: #777E90;
    font-weight: 600;
    background-color: transparent;
    border: none;

}
.home_button_grp{
    display: flex;
    flex-wrap: wrap;          /* Allow buttons to wrap into the next row */
    gap: 10px; 
    box-sizing: border-box;
}


.Button_Buy_premium_home {
    margin: 10px;
    background-color: #FEEFE6;
    text-align: left;
    padding: 10px 30px;
    border: none;
    border-radius: 12px;   
    font-family: Outfit;
    font-weight: 600;
    font-size: 1em;
    height: fit-content;
    box-sizing: border-box; /* Full width of the parent container */
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 20px;
    width: 100%;
}

.Button_Title_home{ 
    
    white-space: nowrap;
    margin: 10px;
    background-color: #EDF6FF;
    text-align: left;
    padding: 10px 30px;
    border: none;
    border-radius: 12px;
      font-family: Outfit;
    font-weight: 600;
    font-size: 1em;
    height: fit-content;
    width: 30%;  /* Full width of the parent container */
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 20px;
    box-sizing: border-box;
}
.Button_content_home{
    white-space: nowrap;
    margin: 10px;
    background-color: #FFEDED;
    text-align: left;
    padding: 10px 30px;
    border: none;
    border-radius: 12px;    
    font-family: Outfit;
    font-weight: 600;
    font-size: 1em;
    height: fit-content;
    width: 30%;;  /* Full width of the parent container */
    display: inline-flex;
    flex-direction: row;
    align-items: center;
    gap: 20px;
    box-sizing: border-box;
    }
.Button_Outline_home{ 
 
    white-space: nowrap;
    margin: 10px;
    background-color: #E2FFEC;
    text-align: left;
    padding: 10px 30px;
    border: none;
    border-radius: 12px;
     font-family: Outfit;
    font-weight: 600;
    font-size: 1em;
    height: fit-content;
    width: 30%;  /* Full width of the parent container */
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 20px;
    box-sizing: border-box;
    }
.Button_Book_home {
   
    min-width: 120px !important;
    white-space: nowrap;
    margin: 10px;
    background-color: #F0EEFF;
    text-align: left;
    padding: 10px 30px;
    border: none;
    border-radius: 12px;   
    font-family: Outfit;
    font-weight: 600;
    font-size: 1em;
    height: fit-content;
    width: 100%;  /* Full width of the parent container */
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 20px;
    box-sizing: border-box;
    
}
.Button_home{
    height: 8vh;
}

@media(max-width:600px){
    .Button_Buy_premium_home{
        font-size: 0.8em;
        margin: 0px;
    }
.Button_Title_home,.Button_Book_home,.Button_Outline_home,.Button_content_home{
    width: 48%;
    padding: 10px;
    font-size: 0.6em;
    gap:10px;
    margin: 0px;

}

.Button_home{
    height: 4vh;
}

    
}


.back-button-dashboard { 
  color: #8d12ff;
  background-color: transparent;
  width: 2em;   
  gap: 15px;
  margin-right: 10px;
  border: none;
  background: none;
  cursor: pointer;
  display: flex;
  align-items: center; 
  margin:5px;
}



.form{
    margin-top: 20px;  
    text-align: center;      
     
}
.topic{
    font-size: 18px;
    font-family:Outfit; 
    font-weight: 700;
    margin-bottom: 5px;
    text-align: left; 
}

.input_1{
    font-family:Outfit;
    font-weight: 400;
    width: 100%;
    height: 80px;
    border-radius: 8px;
    background-color: transparent;
    border: 1px solid gray;
    padding: 10px;
    box-sizing: border-box;
    color: white;
}

option{
  color: black;
}

.input_3 {
  font-family: Outfit, sans-serif;
  font-weight: 400;
  width: 100%;
  height: 50px;
  border-radius: 8px;
  border: 1px solid gray;
  background-color: transparent;
  padding: 10px;
  box-sizing: border-box;
  color: white;
}
.input_1:hover,
.input_1:focus {
  border:none; 
  outline: none; 
}
.dropdown-btn_backgroundmusic {
  box-shadow: 0 0 0.2rem rgba(0, 0, 0, 0.7);
  border-radius: 1rem;
  padding-top: 0.25rem;
  padding-bottom: 0.25rem;
  padding-right: 1rem;
  padding-left: 0.5rem;
  border: 0;
  appearance: none;
  -moz-appearance: none;
  -webkit-appearance: none;
  background-image: url('../assets/dropdown_icon.svg');
  background-repeat: no-repeat, repeat;
  background-position: right .7em top 50%;
  background-size: .65em auto;

}
select {
  box-shadow: 0 0 0.2rem rgba(0, 0, 0, 0.7);
  border-radius: 1rem;
  padding-top: 0.25rem;
  padding-bottom: 0.25rem;
  padding-right: 1rem;
  padding-left: 0.5rem;
  border: 0;
  appearance: none;
  -moz-appearance: none;
  -webkit-appearance: none;
  background-image: url('../assets/dropdown_icon.svg');
  background-repeat: no-repeat, repeat;
  background-position: right .7em top 50%;
  background-size: .65em auto;
}


.input_2:hover,
.input_2:focus {
  border:none; 
  outline: none; 
}
.div_input{
    margin-top: 30px;
    display: inline-flex;
    gap: 10px;
}
.input_2{
    font-family:Outfit;
    width: 30.5vw;
    height: 7vh;
    border-radius: 8px;
    background-color: transparent;
    border:1px solid gray;
    padding: 10px;

}

.generate_btn{
    width: 35%;
    margin-top: 60px;
    margin-bottom: 30px;  
     
}
.buy-button_content {
    background-color: var(--main-color);
    color: white;
    padding-top: 12px;
    padding-bottom: 12px;
    border: none;
    border-color: white;
    width: 35%;  
    border-radius: 50px;
    width: 35%;
    margin-top: 60px;
    margin-bottom: 30px;
   
   }



.hisotry_null{

    text-align: center;
}




.hamburger {
    cursor: pointer;
    font-size: 30px;
  }
  
  .overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.8); 
    display: flex;
    justify-content: center;
    align-items: flex-start; 
    z-index: 1000; 
  }
  
  .overlay-content {
    width: 100%;
    height: 100%;
    background: white;
    display: flex;
    flex-direction: column;
  }
  
  .topslider {
    height: 30%;
    position: relative;
    background:  var(--main-color) ;
    display: flex;
    justify-content: center; 
    align-items: flex-end; 
    padding: 10px;

   
    



  }
  
  .close-icon {
    cursor: pointer;
    color: white;
    font-size: 22px; 
    position: absolute; 
    top: 30px; 
    right: 30px; 
    
  }
  
  .frame {
    height: 16vh; 
    display: block;
    margin-bottom: 5px;
    margin-left: 15px;
    justify-self: center;
  }

  .slidercrown{

    width:35px ;
  }

  .buy-premium-slider{
    font-family: Outfit;
    font-weight: 700;
    display: flex;
    flex-direction: row;
    gap: 20px;
    justify-content: center;
    align-items: center;
    padding: 10px;
    margin-top: 30px;
    margin-bottom: 20px;
    width: 80%;
    border-radius: 4dvb;
    border: none;
   font-size: 1.1em;
    color: white;
    background-color: var(--main-color) ;;
  }
  
  .button-container-slider {
    display: flex;
    flex-direction: column;    
    flex-grow: 1;
    align-items: center;
    gap: 25px;
  }
  .slider-Homeimg{
    height: 3vh;
  }
  
  .Home_btn_slider{
    color: #3F4141;
    background-color: transparent;
    border: none;
    font-size: 1em;
    font-family: Outfit;
    font-weight: 700;
    display: flex;
     text-align: left;
     align-items: top;
    gap: 10px;
    width: 45%;
  }
  .Home_btn_slider.active {
    color: var(--main-color); 
   
}
.Home_btn_slider.active .slider-Homeimg{
  filter: brightness(0) saturate(100%) invert(13%) sepia(94%) saturate(5643%) hue-rotate(267deg) brightness(78%) contrast(112%);
  }
  
  .action-button {
    margin: 10px;
    padding: 15px 30px;
    font-size: 18px;
    background-color: #4caf50;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
  }


  #login_slider{
    margin-top: 30%;
    font-size: 1em;
    font-family:Outfit; 
    border-radius:150px;
    background-color: transparent;
    padding: 8px;
    padding-left: 30px;
    padding-right: 30px;
    background-color: transparent;
    color: var(--main-color) ;;
    border: 2px solid  var(--main-color) ;;    
    margin-bottom: 120px;
    width: fit-content;
   
  }
  


  .full-screen-overlay {
    display: flex; /* Use flexbox for centering */
    justify-content: center;
    align-items: center;
    position: absolute; /* Position relative to the parent div */
    top: 0;
    left: 0;
    width: 100%; /* Cover the full width of the parent div */
    height: 100%; /* Cover the full height of the parent div */
    background-color: white; /* Slightly transparent white */
    z-index: 999; /* Just below the loader */
}

/* Loader styles */
.loader {
    width: 60px; /* Size of the spinner */
    height: 60px; /* Size of the spinner */
    position: relative; /* To position dots */
    animation: rotate 1.5s linear infinite; /* Rotation animation */
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
}

.loader::before {
    content: "";
    position: absolute;
    width: 10px; /* Dot size */
    height: 10px;
    background-color: var(--main-color); /* Single color for the dots */
    border-radius: 50%;
    animation: dotRotate 1.5s infinite ease-in-out; /* Dot rotation animation */
}

.loader::after {
    content: "";
    position: absolute;
    width: 10px;
    height: 10px;
    background-color: var(--main-color); 
    border-radius: 50%;
    animation: dotRotate 1.5s infinite ease-in-out 0.2s; /* Slight delay to stagger dots */
}

@keyframes rotate {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}

@keyframes dotRotate {
    0% {
        opacity: 0;
        transform: rotate(0deg) translateX(20px);
    }
    50% {
        opacity: 1;
        transform: rotate(180deg) translateX(20px);
    }
    100% {
        opacity: 0;
        transform: rotate(360deg) translateX(20px);
    }
}
/*  ends here loader css*/
.floating-button {
    position: fixed;
    right: 0px; /* Distance from the right */
    top: 50%; /* Center vertically */
    transform: translateY(-50%); /* Adjust for vertical centering */
    z-index: 1000; /* Ensure it stays above other elements */
    cursor: pointer; /* Change cursor to pointer */
  }
  
  .floating-button img {
    width: 40px; /* Adjust size as needed */
    height:250px; /* Adjust size as needed */
  }

/* Music css*/
.music-dropdown-container_backgroundmusic {
    position: relative;
    width: 100%;
  }
  
  .dropdown_backgroundmusic {
    position: relative;
  }
  
  .dropdown-btn_backgroundmusic {
    text-align: left;
    font-size: 14px;
    font-family: Outfit;
    font-weight: 200;
    width: 100%;
    height: 50px;
    border-radius: 8px;
    border : 1px solid gray;
    background-color:transparent;
    padding: 10px;
    box-sizing: border-box;
    color: white; /* Change this to your desired color */
    display: flex;
    justify-content: space-between;  /* This will space out the text and the arrow */
    align-items: center;             /* Vertically align items in the center */
  }
  
  .dropdown-arrow {      
    font-size: 15px; 
    position: absolute;
    font-weight: 200;
    right:5px; /* Positioning the icon on the right side of the item */
    top: 50%; /* Vertically center the icon */
    transform: translateY(-50%) scaleX(1.5);  /* Change this to your desired color */
  }
  
  .dropdown-content_backgroundmusic {
    display: block;
    position: absolute;
    background-color: #f9f9f9;
    min-width: 100%;
    max-height: 150px;
    overflow-y: auto;
    box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.2);
    z-index: 1;
  }
  
  .dropdown-item_backgroundmusic {
    color: black;
    padding: 12px;
    cursor: pointer;
    display: flex;
    justify-content: space-between;
    text-align: left;
  }
  
  .play-pause-btn_backgroundmusic {
    width: 30px;
    height: 30px;
    border-radius: 50%;
    background-color: var(--main-color);
    color: white;
    font-size: 1em; /* Adjusted font size for consistency */
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
  }
  
  .dropdown-btn_backgroundmusic:focus + .dropdown-content_backgroundmusic {
    display: block;
  }
  








/* Container for the loader */
/* Container for the loader - will be placed inside the div it's supposed to cover */
.loader-container-main {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 100%;
    height: 100%;
    z-index: 1000; /* Set it to be above other content, but ensure it doesn't interfere with the slider */
    pointer-events: none; /* Allow interaction with elements below the loader */
  }
  
  /* The spinning loader */
  .loader-custom {
    border: 4px solid rgba(255, 255, 255, 0.3);
    border-top: 4px solid var(--main-color);
    border-radius: 50%;
    width: 50px;
    height: 50px;
    animation: spin 1.5s linear infinite;
  }
  
  /* Text shown below the spinner */
  .loading-text {
    margin-top: 20px;
    color: white;
    font-size: 16px;
    font-family: Arial, sans-serif;
    display: block; /* Make sure the text is shown */
  }
  
  /* Keyframe for spinning animation */
  @keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
  }
  
  /* Show the "Generating Video" message */
  .loader-container-main.show-generating .loading-text {
    display: block;
  }
  .main_panel {
    position: relative; /* Ensures child elements can be positioned inside it */
    width: 100%;
    height: 100%; /* Or any height based on the content inside */
  }


  /* Disable scrolling */
.no-scroll {
    overflow: hidden;
  }
  
  /* Disable all pointer events (clicks, touches, etc.) when the loader is visible */
  .no-interaction {
    pointer-events: none;
  }
  


  /* css for font dropdown*/
/* Main container */
#app-container {
    width: 100%;
    display: flex;
    justify-content: center;
    padding: 0px;
    margin-bottom: 10px;
  }
  
  /* Scrollable container for the boxes */
  #scrollable-box-container {
    display: flex;
    justify-content: flex-start;
    width: 100%;
    flex-wrap: nowrap; /* Prevents wrapping of boxes */
    gap: 5px;
    box-sizing: border-box;
    overflow-x: auto; /* Enable horizontal scrolling */
  }
  
  /* Each individual box styling */
  #box-item-0, #box-item-1, #box-item-2, #box-item-3, #box-item-4, #box-item-5,#box-item-6 {
    display: flex;
    justify-content: center;
    align-items: center;
    min-width: 80px;
    max-width: 150px; /* Adjust size of boxes */
    height: 40px; /* Ensures box remains square */
    background-color: white;
    border-radius: 5px;
    border: 0.5px solid #ccc;
    cursor: pointer;
    transition: all 0.3s ease;
  }
  #box-text-1{
    font-family: Outfit !important;
  }
  /* Text inside the box */
  #box-text-0, #box-text-1, #box-text-2, #box-text-3, #box-text-4, #box-text-5 , #box-text-6, #box-text-7 {
    font-size: 12px;
    font-weight: bold;
    color: #333;
    text-align: center;
  }

  
  /* Styling for the selected box */
  .box-selected {
    background-color: #5D0AD1 !important;
    border-color: lightgray;
    
    font-family: 'Oswald', sans-serif !important;
  }
  
  /* Ensure text color is orange when selected */
  .box-selected .box-text {
    color: white  !important;
  }
  
  /* Ensure box border is orange when selected */
  .box-selected {
    border:2px solid #5D0AD1 !important;
  }
  
  /* Responsive Design: Enable Horizontal Scroll */
  @media (max-width: 900px) {
    #scrollable-box-container {
      gap: 10px; /* Increase gap for better visibility */
      overflow-x: auto; /* Enable horizontal scroll */
      padding-bottom: 10px; /* Add padding at the bottom if needed */
    }
  
    #box-item-0, #box-item-1, #box-item-2, #box-item-3 {
      flex-shrink: 0; /* Prevent boxes from shrinking when scrolling */
      min-width: 80px;
      height: 40px;
       /* Ensures boxes stay large enough */
    }
    #box-item-0,#box-item-4, #box-text-6{
      font-size: 14px;
    } 
  }
  
  /* General box sizing for large screens */
  @media (min-width: 901px) {
    #scrollable-box-container {
      justify-content: space-between; /* Align boxes in a row */
    }
  
    #box-item-0, #box-item-1, #box-item-2, #box-item-3, #box-item-4, #box-item-5,#box-item-6 {
      flex-basis: 30%; /* Boxes take 30% of container width on larger screens */
    }
  }
  
  
  
  